<template>
  <div
    v-show="!blocker.blocked"
    class="page pa-0"
  >
    <m-alerts
      :data="alertsByPriority.high"
      class="mx-4 my-4"
      @open="toggleView"
    />

    <m-cycles
      :data="hours.cycles"
      :loading="loader.hours.loading"
      :updated="!!loader.hours.updatedAt"
      :user="user"
      class="elevation-12 mx-4 my-4"
      @help="(params) => toggleView({ view: 'help', ...params })"
    />

    <m-buzzer 
      :buzzer="buzzer"
      :loading="loader.buzzer.loading"
      :updated="!!loader.hours.updatedAt"
      :active="active"
      :installed="installed"
      class="elevation-12 my-4 mx-4"
      @help="(params) => toggleView({ view: 'help', ...params })"
      @connect="onConnectedDisplay"
      @toggle-standby="toggleDisplaySTB"
      @alert="toggleToast"
      @get="getStatusDisplay"
    />

    <m-alerts
      :data="alertsByPriority.low"
      class="mx-4 my-4"
      @open="toggleView"
    />

    <div 
      class="metrics my-4 px-4 d-flex scrollable x"
    >
      <v-sheet
        v-for="(metric, i) in metrics"
        :key="'metric-'+i"
        width="36vw"
        min-height="8rem"
        color="secondary darken-4"
        class="metric pa-5 rounded-lg align-stretch flex-shrink-0"
      >
        <v-card-text
          v-if="!loader.metrics.loading"
          class="metric-content fill-height d-flex flex-column justify-center pa-0"
        >
          <p class="metric-number">{{ metric.prefix }}{{ metric.value }}{{ metric.suffix }}</p>
          <p 
            class="metric-text text-caption ma-0"
            :inner-html.prop="metric.text | format(formatText)"
          />
        </v-card-text>
        <v-skeleton-loader
          v-else
          type="paragraph"
          tile
          min-height="4rem"
        />
      </v-sheet>
    </div>

    <div 
      class="reminder text-center px-8 mt-6 my-4"
    >
      <p class="text-overline grey--text mb-2">
        Lembrete
      </p>
      <p class="text-body-2 grey--text text--lighten-3">
        {{ reminder }}
      </p>
    </div>

  </div>
</template>

<style lang="scss">

  .metrics {
    .metric-number {
      font-size: 1.125rem;
    }
    .metric:not(:last-child) {
      margin-right: 8px;
    }
  }
  
</style>

<script>

  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { reminders } from '@/services/home'

  export default {
    props: {
      blocker: {
        type: Object,
        default: () => null
      },
      alerts: {
        type: Array,
        default: () => []
      },
      user: {
        type: Object,
        default: () => null
      },
      active: {
        type: Boolean,
        default: true
      },
      dev: {
        type: Boolean,
        default: false
      },
      installed: {
        type: Boolean,
        default: false
      },
      // buzzer: {
      //   type: Object,
      //   default: () => {}
      // }
    },
    data: () => ({
      controller: {
        metrics: {
          total_income: {
            value: null,
            prefix: 'R$ ',
            suffix: null,
            text: 'Renda total na Mobees'
          },
          maintenance_avg_time: {
            value: null,
            suffix: ' min',
            text: '--Tempo médio-- atendimento'
          },
          maintenances: {
            value: null,
            text: 'Atendimentos neste Ciclo'
          },
          review_status: {
            value: null,
            suffix: ' dias',
            text: 'Para próxima Revisão'
          },
          bonus: {
            value: null,
            text: 'Tickets para Sorteio'
          },
          referrals: {
            value: null,
            text: 'Motoristas indicados'
          },
        },
      },
      loader: {
        hours: {
          loading: false,
          updatedAt: null,
          lazyness: [1, 'minutes']
        },
        buzzer: {
          loading: false,
          updatedAt: null,
          lazyness: [5, 'minutes']
        },
        metrics: {
          loading: false,
          updatedAt: null,
          lazyness: [4, 'hours']
        },
      }
    }),
    computed: {
      hours: sync('payments/data@hours'),
      buzzer: sync('app/buzzer'),

      metrics () {
        const loading = !this.loader.metrics.updatedAt;
        const metrics = this.controller.metrics;
        const list = loading ? _.times(3) : _.reject(metrics, ['value', null]);
        return list;
      },

      reminder () {
        const text = _.sample(reminders);
        return text;
      },

      hasAlerts () {
        return _.size(this.alerts)>0;
      },
      alertsByPriority () {
        const alerts = this.alerts;
        const list = _.groupBy(_.map(alerts, (alert) => {
          return {
            ...alert,
            flag: alert.priority>=50 ? 'high' : 'low'
          }
        }), 'flag');
        const { high=[], low=[] } = list;
        return { high, low };
      }
    },

    watch: {
      active (active) {
        if (active) this.load();
      },
      alerts: {
        deep: true,
        immediate: true,
        handler (alerts) {
          const review = _.find(alerts, ['type', 'review']);
          if (!!review) {
            this.controller.metrics.review_status.value = review.data.expiresIn<0 ? 0 : review.data.expiresIn;
          }
        }
      }
    },

    filters: {
      format (text, formatter=(v)=>v) {
        return formatter(text);
      }
    },

    methods: {
      ...services,

      load () {
        this.getCycles();
        this.getStatusDisplay();
        this.getMetrics();
      },

      getCycles () {
        const loader = this.loader.hours;
        loader.updatedAt = this.hours.updatedAt;
        const update = this.shouldUpdate(loader);
        if (!update) return;
        
        loader.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        console.log('getCycles...');
        this.$api
          .get('/getworkhours/'+cpf+'/'+token)
          .then(response => {
            console.log('getCycles => ',response);
            if (response.data.retorno==200) {
              const cycles = _.reject(response.data.ciclos, ['dt_inicio_quinzena', null]);
              const data = _.mapValues(_.groupBy(cycles, 'dt_inicio_quinzena'), ciclo => {
                const start = this.$moment.utc(ciclo[0].dt_inicio_quinzena).local().format('YYYY-MM-DD HH:mm:ss');
                const end = this.$moment.utc(ciclo[0].dt_fim_quinzena).local().format('YYYY-MM-DD HH:mm:ss');
                return {
                  inicio_ciclo: start,
                  fim_ciclo: end,
                  horas_diarias: _.map(ciclo, c => {
                    return {
                      dia: c.dia,
                      hora: c.hora,
                      validas: _.isNil(c.horas_validas) ? 0 : c.horas_validas,
                      invalidas: _.isNil(c.horas_invalidas) ? 0 : c.horas_invalidas,
                      compensadas: _.isNil(c.horas_compensadas) ? 0 : c.horas_compensadas,
                    }
                  })
                }
              });
              if (!_.isEmpty(data)) {
                _.each(_.mapKeys(_.mapValues(data, cycle => {
                  const hours = _.reduce(cycle.horas_diarias, (calc, h) => {
                    return {
                      valid: calc.valid + h.validas,
                      invalid: calc.invalid + Math.abs(h.invalidas),
                      compensated: calc.compensated + h.compensadas,
                    }
                  }, { valid: 0, invalid: 0, compensated: 0 });
                  return {
                    start: cycle.inicio_ciclo,
                    end: cycle.fim_ciclo,
                    valid: _.round(hours.valid, 1),
                    invalid: _.round(hours.invalid, 1),
                    compensated: _.round(hours.compensated, 1),
                    daily: _.map(_.groupBy(cycle.horas_diarias, 'dia'), (d) => {
                      return {
                        day: d[0].dia,
                        valid: _.sumBy(d, 'validas'),
                        invalid: Math.abs(_.sumBy(d, 'invalidas')),
                        compensated: _.sumBy(d, 'compensadas'),
                      }
                    })
                  }
                }), cycle => this.$moment(cycle.start).format('YYYY-MM')), (cycle, key) => {
                  this.$set(this.hours.cycles, key, cycle);
                });
  
                // this.hours.selected = this.hours.cycles.length - 1;
              }
              this.hours.updatedAt = loader.updatedAt = this.$moment().valueOf();
              this.$set(this.hours, 'updatedAt', loader.updatedAt);

            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Aguardando resposta do sistema...');
              setTimeout(($, cpf, token) => {
                $.getCycles(cpf, token);
              }, 5000, this, cpf, token);
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

      getMetrics () {
        const loader = this.loader.metrics;
        const update = this.shouldUpdate(loader);
        if (!update) return;
        
        loader.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        console.log('getMetrics...');
        this.$api
          .get('/getmetrics/'+cpf+'/'+token)
          .then(response => {
            console.log('getMetrics => ',response);
            _.each(response.data, (value, key) => {
              if (value>=1000) {
                value = _.round(value/1000);
                this.controller.metrics[key].suffix = ' mil';
              }
              this.controller.metrics[key].value = value;
            });
            loader.updatedAt = this.$moment().valueOf();
          })
          .catch(error => {
            if (error.status==401) {
              this.getout();
              this.handleError(error, 'Sua sessão expirou...');
            }else{
              this.handleError(error, 'Aguardando resposta do sistema...');
              setTimeout(($, cpf, token) => {
                $.getMetrics(cpf, token);
              }, 5000, this, cpf, token);
            }
          })
          .finally(() => {
            loader.loading = false;
            this.getCountReferrals();
            this.getCountTickets();
          });
      },

      toggleStandbyConfirmation: function (b){
        this.standbyConfirmation.toggle = b;
      },

      toggleDisplaySTB: function (b){
        if(!b){
          this.toggleStandbyConfirmation(true);
        }else{
          this.setStatus(b);
        }
      },

      setStatus (b) {
        this.toggleStandbyConfirmation(false);
        this.setStatusDisplay(b);
      },

      updateDisplay (status) {
        this.$set(this.buzzer, 'toggle', !(status=='STB'||status=='OFF'));
      },

      onConnectedDisplay (b) {
        const status = b ? 'ON' : this.buzzer.status;
        this.$set(this.buzzer, 'status', status);
      },

      getStatusDisplay () {
        const loader = this.loader.buzzer;
        loader.updatedAt = this.buzzer.updatedAt;
        const update = this.shouldUpdate(loader);
        if (!update) return;
        
        loader.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        this.$api
          .get('/getstatusdisplay/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getStatusDisplay => ', response);
            if (response.data.retorno==200) {
              this.$set(this, 'buzzer', _.merge(this.buzzer, {
                status: response.data.statusDisplay,
                cache: response.data.cache>0||response.data.total_cache>0 ? true : false,
                cached: response.data.total_cache,
                timestamp: {
                  status: response.data.ultimo_status,
                  impression: response.data.ultima_impressao,
                },
                mode: response.data.modeOperacao,
                id: response.data.id_device,
                code: response.data.cod_device,
              }));

              this.updateDisplay(this.buzzer.status);

              this.buzzer.updatedAt = loader.updatedAt = this.$moment().valueOf();

            }else{
              this.toggleToast(
                true,
                'Não foi possível conectar com o Buzzer. Verifique sua conexão.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.buzzer.loading = false;
          });
      },

      getCountReferrals () {

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        this.$api
          .get('/getcountreferrals/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getCountReferrals => ', response);
            if(response.data[0].retorno>0){
              this.controller.metrics.referrals.value = response.data[0].countReferrals.toString()
            }else{
              // this.toggleToast(
              //   true,
              //   'Erro nas indicações',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getCountTickets () {

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        this.$api
          .get('/getcounttickets/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getCountTickets => ', response);
            if(response.data[0].retorno>0){
              this.controller.metrics.bonus.value = response.data[0].countTickets.toString();
              // this.controller.metrics.bonus.total = response.data[0].totalBilhetes;
            }else{
              // this.toggleToast(
              //   true,
              //   'Erro nos tickets',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      setStatusDisplay (b) {
        this.buzzer.loading = true;

        const status = !b ? 'STB_ON' : 'STB_OFF';
        console.log('STB', status);

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        this.$api
          .get('/setstatusdisplay/'+cpf+'/'+token+'/'+status+'/?format=json')
          .then(response => {
            console.log('setStatusDisplay => ', response);
            if(response.data.retorno=='200'){
              this.buzzer = Object.assign({}, this.buzzer, {
                status: response.data.statusDisplay
              });
              this.updateDisplay(this.buzzer.status);

              this.trackEvent('buzzer', 'set status '+status);

            }else if(response.data.retorno=='408'){
              this.buzzer.status = response.data.statusDisplay;
              this.updateDisplay(this.buzzer.status);
              this.toggleToast(
                true,
                'Não foi possível conectar com o Buzzer. Favor tentar novamente.',
                5000,
                false
              );
              this.trackEvent('buzzer', 'buzzer unresponsive');
            }else if(response.data[0].retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.buzzer.loading = false;
          });
      },
    },

    created () {
    },
    mounted () {
      this.load();
    },

    components: {
      mCycles: () => import('@/components/mCycles'),
      mBuzzer: () => import('@/components/mBuzzer'),
      mAlerts: () => import('@/components/mAlerts'),
    }
  }
</script>