// Home Dicts & Funcs

import { 
  mdiGiftOutline,
  mdiSaleOutline,
  mdiPoll,
  mdiCurrencyUsd,
  mdiHelpCircleOutline
} from '@mdi/js';

export const pages = {
  bonus: {
    title: 'Bonus',
    icon: mdiGiftOutline,
    disabled: false,
    route: { path: '/main/bonus' }
  },
  club: {
    title: 'Clube',
    icon: mdiSaleOutline,
    disabled: false,
    route: { query: { view: 'help', section: 'benefits' } }
  },
  home: {
    title: 'Home',
    icon: mdiPoll,
    disabled: false,
    route: { path: '/main/home' }
  },
  payments: {
    title: 'Extrato',
    icon: mdiCurrencyUsd,
    disabled: false,
    route: { path: '/main/payments' }
  },
  help: {
    title: 'Ajuda',
    icon: mdiHelpCircleOutline,
    disabled: false,
    route: { query: { view: 'help' } }
  },
}

export const reminders = [
  'Já conferiu as garras do seu rack hoje? 🔐',
  'Já conferiu se tem algum Led soltando em seu Buzzer?',
  'Cuidado ao entrar e sair de estacionamentos, condomínios e hotéis. Verifique a altura do portão e da entrada / saída. 🔝',
  'Cuidado ao colocar seu veículo em elevadores! Informe ao mecânico para não pressionar o Buzzer no teto.',
  'Atenção aos fios arriados nas ruas! Cuidado para não prender no Buzzer!',
  'Vai rodar? Não deixe de ligar seu Buzzer mesmo que já tenha alcançado 160 horas de anúncios. Esse é o nosso combinado! 🤝',
  'Não compareça na Colmeia sem agendamento ou fora do horário! 🚫',
  'Não use um agendamento de revisão para resolver um problema de seu Buzzer. Agende um horário de manutenção. 🛠🪛',
  'O repasse de seus ganhos é realizado sempre na data informada no App, até 23:59h. 🤑',
  'É de sua responsabilidade realizar a revisão periódica a cada 30 dias. O App te ajuda a lembrar! ✅',
  'Nosso atendimento é realizado exclusivamente pelo App! Não temos atendimento via WhatsApp.',
  'O rack é exclusivo para transporte do Buzzer. Não utilize para carregar nenhum objeto além do Buzzer.',
  'Sempre que tiver um problema com o Buzzer ou rack, procure abrir o chamado no App o mais rápido possível e procure agendar a data mais próxima possível. 🦾'
]

export default {
  pages,
  reminders
}